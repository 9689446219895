<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
       <!-- <li class="nav-item mr-auto"> -->
            <b-link

              to="/acesso-credor"
            >
              <span class="brand-logo">
                <b-img
                  src="@/assets/images/logo/credit-azul.png"
                  alt="logo"
                />
              </span>
            </b-link>
          <!-- </li> -->
     
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bem vindo ao Credit! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Por favor preencha suas informações e faça o login
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              ref="loginForm"
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- codigo -->
              <b-form-group
                label="Código do credor"
                label-for="login-codigo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Código"
                  rules="required|integer"
                >
                  <b-form-input
                    id="login-codigo"
                    v-model="credorCodigo"
                    :state="errors.length > 0 ? false:null"
                    name="login-codigo"
                    placeholder="112233"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Esqueceu sua senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import auth from '@/auth/authService'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      credorCodigo: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {   
          // Make Login request using JWT request
          auth.loginCredor({
            codigo: this.credorCodigo,
            password: this.password,
          })
          .then(response => {
            // `response.data` is response from API which is above mentioned
            const { userData } = response.data

            // Setting access token in localStorage
            // NOTE: Please check the source code to better understand JWT service
            auth.setToken(response.data.accessToken)

            // Setting user data in localStorage
            localStorage.setItem('userData', JSON.stringify(userData))

            // Updating user ability in CASL plugin instance
            this.$ability.update(userData.ability)

            // Redirection after login
            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Bem vindo ${userData.name}`,
                //   title: 'Welcome Vinicius Stanoga',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                  text: `Você realizou seu login com sucesso. Agora você pode explorar o Credit!`,
                },
              })
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                // title: `Welcome ${userData.fullName || userData.username}`,
                title: 'Falha na autenticação',
                icon: 'XIcon',
                variant: 'danger',
                // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                text: `Por favor verifique suas credenciais e tente novamente.`,
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>